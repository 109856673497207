
// Base
// Headvisual
// Intro text
// Footer


//
// =Base
// --------------------------------------------------

@import "partials/media-queries";
// @import "partials/layout";
// @import "partials/animations";

// @import "partials/slides";
// @import "partials/lint";


//
// =Buy Now picker
// --------------------------------------------------

.msdw-buy-now-picker {
  ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    padding: 0;
    list-style-type: none;

    @media #{$lg-up} {
      gap: 20px 40px;
    }

    a {
      display: flex;
      align-items: center;
      gap: 5px;

      /* These are technically the same, but use both */
      overflow-wrap: break-word;
      word-wrap: break-word;

      -ms-word-break: break-all;
      /* This is the dangerous one in WebKit, as it breaks things wherever */
      word-break: break-all;
      /* Instead use this non-standard one: */
      word-break: break-word;

      /* Adds a hyphen where the word breaks, if supported (No Blink) */
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;

      @media #{$md-up} {
        gap: 10px;
      }

      img {
        max-width: 18px;

        @media #{$md-up} {
          max-width: 22px;
        }
      }
    }
  }

  button {
    background: none;
    border: 0;
  }

  button[aria-selected="true"] {
    border-bottom: 2px solid #f9ba00;
  }
}


// .msdw-store-option-content {
//   display: none;
// }

// .msdw-store-option-check-1:checked ~ .msdw-store-option-label-1,
// .msdw-store-option-check-2:checked ~ .msdw-store-option-label-2 {
//   border-bottom: 2px solid #f9ba00;
// }

// .msdw-store-option-check-1:checked ~ .msdw-store-option-content-1,
// .msdw-store-option-check-2:checked ~ .msdw-store-option-content-2 {
//   display: block;
// }
